#header {
  min-height: max-content;
  display: grid;
  align-items: center;
  grid-template-columns: max-content auto;
  grid-template-areas: "image titleText";
}

#titleSection {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-self: center;
  height: 150px;
  width: 475px;
  max-width: 100vw;
  justify-content: flex-end;
  align-items: center;
}

#firstName {
  color: var(--COLOR-1);
  margin: 0px;
  font-weight: 800;
  font-size: 120pt;
  position: fixed;
  top: -2vw;
  z-index: -1;
  opacity: 0.9;
}

#lastName {
  align-self: flex-end;
  justify-self: flex-end;
  font-weight: 200;
  font-size: 32pt;
  color: var(--COLOR-3);
  margin: 0px;
}

@media (max-width: 400px) {
  #firstName {
    font-size: 95pt;
  }

  #titleSection {
    height: 140px;
    width: 450px;
    max-width: 95vw;
  }
}

#headerImage {
  grid-area: image;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20vw;
  height: 20vw;
  max-width: 125px;
  max-height: 125px;
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  background-color: var(--accent-color-dark);
  overflow: hidden;
}

#titleSection {
  grid-area: titleText;
}

#innerCircle {
  width: 95%;
  height: 95%;
  border-radius: 50%;
  overflow: hidden;
}

#profileImage {
  width: 115%;
  margin-top: -20px;
  margin-left: -5px;
}
