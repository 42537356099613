.projectBrick {
  flex: 1;
  max-width: 600px;
  min-width: 600px;
  height: auto;
  border-radius: 4px;
  background-color: white;
  padding: 12px;
  padding-left: 8px;
  display: grid;
  grid-template-columns: 6fr 5fr;
  grid-template-areas: "image description" "image description" "image description" "logos button";
  row-gap: 10px;
  align-items: center;
  margin: 12px;
}
.projectBrickImgContainer {
  grid-area: image;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative;
}

.brickImagePhone {
  width: 35%;
  max-width: 105px;
  align-self: center;
  justify-self: center;
}

.brickImageLaptop {
  width: 85%;
  align-self: center;
  justify-self: center;
}

@media (max-width: 600px) {
  .projectBrick {
    grid-template-columns: auto;
    grid-template-areas: "image" "description" "logos" "button";
    min-width: 325px;
  }
}

.brickDetails {
  grid-area: description;
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.brickTitle {
  margin: 0px;
  font-size: 24pt;
  font-weight: 500;
}

.brickDate {
  margin: 0px;
  font-size: 8pt;
  font-weight: 400;
}

.brickLogoContainer {
  grid-area: logos;
}

.brickJob {
  margin: 0px;
  font-weight: 500;
  font-size: 11pt;
  margin-bottom: 10px;
}

.brickDescription {
  margin: 0px;
  font-size: 10pt;
  font-weight: 300;
}

.brickButton {
  grid-area: button;
  font-size: 10pt;
  color: white;

  padding: 4px 6px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
}

.brickButtonLink {
  justify-self: center;
}

.brickLogoContainer {
  display: flex;
  justify-self: center;
  width: 80%;
  border-radius: 500px;
  padding: 4px 2px;
  justify-content: space-around;
}

.brickLogo {
  width: 25px;
  height: 25px;
}
