#bubbleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width: 90vw;
  height: 90vw;
  max-width: 600px;
  max-height: 500px;
  position: relative;
  background-color: rgb(23, 23, 23, 0.7);
  overflow: hidden;
}

.bubbleCover {
  width: 100%;
  height: 100%;
  background-color: var(--COLOR-3);
  position: absolute;
  top: 0px;
  transition: top 0.5s ease;
}

.skillLevel {
  position: absolute;
  bottom: 30px;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-color: white;
  padding: 2px 4px;
  border-radius: 4px;
}

.skillLevelText {
  margin: 0px;
  font-size: 9pt;
  font-weight: 500;
}

.bubbleRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

#bubbleBtn {
  position: absolute;
  top: 10px;
}

.bubble {
  margin-left: 10px;
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 12px;
  background-color: var(--COLOR-3);
  background: rgb(236, 255, 0);
  background: linear-gradient(
    180deg,
    rgba(236, 255, 0, 1) 0%,
    rgba(255, 177, 0, 1) 50%,
    var(--COLOR-4)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.8s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.bubbleImageWrapper {
  width: 99%;
  height: 99%;
  border-radius: 50%;
  background-color: var(--COLOR-3);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.bubbleImage {
  width: 65%;
  max-height: 70%;
  min-width: 15px;
  min-height: 15px;
  position: absolute;
}

.bubbleText {
  margin: 0px;
}

@media (max-width: 400px) {
  #bubbleContainer {
    width: 95vw;
    height: 105vw;
  }
  .bubble {
    max-width: 80px;
    max-height: 80px;
    margin-left: 5px;
  }
}

#bubbleDetails {
  width: 90%;
  height: 90%;
  background-color: var(--COLOR-3);
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

#bubbleDetailImageWrapper {
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
}
#bubbleDetailImage {
  width: 60%;

  max-height: 60%;
  align-self: center;
}

#bubbleDetailsInfo {
  margin: 10px;
  padding: 4px;
  top: 10px;
}

#bubbleDetailTitle {
  font-size: 24pt;
  margin: 0px;
  font-weight: 600;
}

#bubbleDetailText {
  font-weight: 300;
  margin-top: 4px;
  font-size: 11pt;
}

#bubbleDetailsSkillWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 4px;
}

#bubbleDetailsSkill {
  position: absolute;
  background-color: grey;
  height: 2px;
  left: 0px;
  transition: left 1s ease;
}

#bubbleDetailsMeter {
  width: 100%;
  height: 2px;
  margin-bottom: 4px;
  background: rgb(236, 255, 0);
  background: linear-gradient(
    270deg,
    rgba(236, 255, 0, 1) 0%,
    rgba(255, 177, 0, 1) 50%,
    var(--COLOR-4)
  );
  position: relative;
  overflow: hidden;
}

#bubbleDetailSkillPercentage {
  margin: 0px;
  font-size: 9pt;
  align-self: flex-end;
}
