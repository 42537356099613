#navbar {
  grid-area: nav;
  display: grid;
  grid-template-areas: "navigation" "underline";
  width: 100vw;
  color: rgb(23, 23, 23);
  background-color: rgb(23, 23, 23, 0.7);
}

#navigationOptions {
  width: max-content;
  min-width: 375px;
  grid-area: navigation;
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 400px) {
  #navigationOptions {
    width: 100vw;
  }
}

.navOption {
  color: var(--COLOR-3);
  margin: 6px;
  cursor: pointer;
  font-weight: 100;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 100;
}

#underline {
  grid-area: underline;
  width: 80%;
  border-bottom: 2px solid var(--COLOR-4);
  margin: auto;
  transition: margin 300ms, width 300ms;
}

.NavbarCell {
  padding: 0px 4px;
}
