.imageLoader {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  background-color: var(--COLOR-1);
}

.loaderWave {
  position: absolute;
  animation: loopWave 2s infinite;
  height: 100%;
  background-color: transparent;
  box-shadow: 0 0 30px 15px rgb(255, 255, 255);
}

@keyframes loopWave {
  from {
    left: -20%;
  }
  to {
    left: 130%;
  }
}
