#projects {
  display: flex;
  flex-wrap: wrap;
  grid-area: projects;
  justify-content: space-evenly;
  margin-top: 12px;
}

.projectContainer {
  flex: 1;
  margin: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
  padding: 12px;
  height: max-content;
}

@media (max-width: 400px) {
  .projectContainer {
    margin: 6px;
    padding: 0px;
  }
}

.projectContainerTitle {
  color: var(--title-color);
  font-size: 20pt;
}

@media (max-width: 400px) {
  .projectContainerTitle {
    font-size: 16pt;
  }
}
