@import url("https://fonts.googleapis.com/css?family=Merriweather|Montserrat|Source+Sans+Pro&display=swap");

body {
  margin: 0;
  font-family: "Muli", sans-serif, "Montserrat", sans-serif, "Merriweather",
    serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: var(--COLOR-background);
  -webkit-font-smoothing: subpixel-antialiased;
  overflow-x: hidden;
}

:root {
  --accent-color-dark: #ff9902;
  --background-gradient-dark: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(45, 45, 45, 0.7) 0%,
    rgba(128, 128, 128, 0.4) 100%
  );
  --title-color: rgb(255, 255, 255);
  --text-color: black;
  --COLOR-background: #1d3557;
  --COLOR-1: #457b9d;
  --COLOR-2: #a8dadc;
  --COLOR-3: white;
  --COLOR-4: #e63946;
}

.shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.staticShadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.constantShadow {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
/*

@media (max-width: 400px) {
  body {
    height: 100vh;
    background-image: -webkit-linear-gradient(-75deg, #2e2e2e 50%, #434242 50%);
  }
}
@media (min-width: 401px) {
  body {
    height: 100vh;
    background-image: -webkit-linear-gradient(-40deg, #2e2e2e 50%, #434242 50%);
  }
}*/
