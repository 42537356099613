.note {
    position: fixed;
    background: linear-gradient(-150deg, transparent 1.5em, #feff9c 0);
    width: 150px;
    height: 150px;
    top: 5%;
    right: 5%;
    transform: rotate(10deg);
    border-radius: 4px;
    z-index: 999;
    padding: 10px;
    font-size: 10pt;
    font-weight: 400;
    
  }

  .note > p{
    margin: 0px;
  }

/*   .note{
    position: relative;
    background: linear-gradient(-150deg, transparent 1.5em, #58a 0);
    border-radius: .5em;
  }
 */

.note:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(to left bottom, transparent 50%, rgba(0,0,0,.2) 0, rgba(0,0,0,.4)) no-repeat 100% 0;
    width: 1.73em;
    height: 3em;
    transform: translateY(-1.3em) rotate(-30deg);
    transform-origin: bottom right;
    border-bottom-left-radius: inherit;
    box-shadow: -.2em .2em .3em -.1em rgba(0,0,0,.15);
  }