#skillContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  grid-area: projects;
  justify-content: space-evenly;
  margin-top: 12px;
}

.skillSubContainer {
  margin: 12px;
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: max-content;
  min-width: 300px;
}

.wrapperDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}

.skillMainTitle {
  color: var(--title-color);
  font-size: 20pt;
  margin-bottom: 12px;

  justify-self: center;
}

@media (max-width: 400px) {
  .skillMainTitle {
    font-size: 16pt;
  }
}

#searchHelperText {
  font-size: 9pt;
  font-weight: 400;
  margin: 0px;
  margin-bottom: 2px;
  color: var(--title-color);
}
#searchWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}

#searchContainer {
  display: flex;
  width: max-content;
  min-width: 250px;
  background-color: rgb(23, 23, 23, 0.7);
  padding: 8px;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
}

#skillSearchField {
  width: 95%;
  border: none;
  border-radius: 0px;
  outline: none;
  padding: 6px;
  background-color: transparent;
  color: var(--COLOR-3);
  border-bottom: 2px solid black;
  font-size: 10pt;
  transition: all 0.4s;
}

#skillSearchField:focus {
  border-bottom: 2px solid var(--COLOR-4);
}

.skillLogo {
  width: 40px;
  height: auto;
  align-self: start;
}

#codeSkills {
  flex: 2;
}
#designSkills {
  flex: 2;
}

#otherSkills {
  flex: 1;
}

#appleWatchContainer {
  position: relative;
  display: flex;
  justify-self: center;
  justify-content: center;
  align-content: center;
  height: 550px;
  overflow: hidden;
  margin-top: 12px;
  width: 100%;
}

@media (max-width: 400px) {
  #appleWatchContainer {
    height: 105vw;
  }
}
