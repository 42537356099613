@import url(https://fonts.googleapis.com/css?family=Merriweather|Montserrat|Source+Sans+Pro&display=swap);
body {
  margin: 0;
  font-family: "Muli", sans-serif, "Montserrat", sans-serif, "Merriweather",
    serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #1d3557;
  background: var(--COLOR-background);
  -webkit-font-smoothing: subpixel-antialiased;
  overflow-x: hidden;
}

:root {
  --accent-color-dark: #ff9902;
  --background-gradient-dark: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(45, 45, 45, 0.7) 0%,
    rgba(128, 128, 128, 0.4) 100%
  );
  --title-color: rgb(255, 255, 255);
  --text-color: black;
  --COLOR-background: #1d3557;
  --COLOR-1: #457b9d;
  --COLOR-2: #a8dadc;
  --COLOR-3: white;
  --COLOR-4: #e63946;
}

.shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.staticShadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.constantShadow {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
/*

@media (max-width: 400px) {
  body {
    height: 100vh;
    background-image: -webkit-linear-gradient(-75deg, #2e2e2e 50%, #434242 50%);
  }
}
@media (min-width: 401px) {
  body {
    height: 100vh;
    background-image: -webkit-linear-gradient(-40deg, #2e2e2e 50%, #434242 50%);
  }
}*/

.note {
    position: fixed;
    background: linear-gradient(-150deg, transparent 1.5em, #feff9c 0);
    width: 150px;
    height: 150px;
    top: 5%;
    right: 5%;
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
    border-radius: 4px;
    z-index: 999;
    padding: 10px;
    font-size: 10pt;
    font-weight: 400;
    
  }

  .note > p{
    margin: 0px;
  }

/*   .note{
    position: relative;
    background: linear-gradient(-150deg, transparent 1.5em, #58a 0);
    border-radius: .5em;
  }
 */

.note:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(to left bottom, transparent 50%, rgba(0,0,0,.2) 0, rgba(0,0,0,.4)) no-repeat 100% 0;
    width: 1.73em;
    height: 3em;
    -webkit-transform: translateY(-1.3em) rotate(-30deg);
            transform: translateY(-1.3em) rotate(-30deg);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    border-bottom-left-radius: inherit;
    box-shadow: -.2em .2em .3em -.1em rgba(0,0,0,.15);
  }

#aboutContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  width: 85vw;
  margin-top: 12px;
  background-color: var(--COLOR-3);
  padding: 12px;
  border-radius: 4px;
  max-width: 600px;
}

#profileWrapper {
  width: 20vw;
  height: 20vw;
  min-width: 150px;
  min-height: 150px;
  max-width: 200px;
  max-height: 200px;
  border-radius: 50%;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-align-content: center;
          align-content: center;
}

#profileImage {
  position: absolute;
  top: 10px;
  left: -8px;
}

#aboutText {
  font-size: 11pt;
  font-weight: 300;
  margin: 0px 20px;
}

.aboutTitle {
  margin: 4px;
  margin-top: 16px;
  font-size: 12pt;
  font-weight: 500;
  color: var(--COLOR-background);
  -webkit-align-self: flex-start;
          align-self: flex-start;
  margin-left: 20px;
}

#interestsRow {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.interest {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.interestIcon {
  height: 25px;
}

.interestText {
  margin: 0px;
  margin-top: 4px;
  font-size: 8pt;
}

#aboutSocialMediaContainer {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin-top: 12px;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.contactCell {
  display: -webkit-flex;
  display: flex;
  width: 100px;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
}

.contactLink {
  text-decoration: none;
  color: inherit;
}
.contactText {
  text-decoration: none;
  margin: 0px;
  font-size: 10pt;
  font-weight: 600;
}

.imageLoader {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  background-color: var(--COLOR-1);
}

.loaderWave {
  position: absolute;
  -webkit-animation: loopWave 2s infinite;
          animation: loopWave 2s infinite;
  height: 100%;
  background-color: transparent;
  box-shadow: 0 0 30px 15px rgb(255, 255, 255);
}

@-webkit-keyframes loopWave {
  from {
    left: -20%;
  }
  to {
    left: 130%;
  }
}

@keyframes loopWave {
  from {
    left: -20%;
  }
  to {
    left: 130%;
  }
}

.projectBrick {
  -webkit-flex: 1 1;
          flex: 1 1;
  max-width: 600px;
  min-width: 600px;
  height: auto;
  border-radius: 4px;
  background-color: white;
  padding: 12px;
  padding-left: 8px;
  display: grid;
  grid-template-columns: 6fr 5fr;
  grid-template-areas: "image description" "image description" "image description" "logos button";
  grid-row-gap: 10px;
  row-gap: 10px;
  -webkit-align-items: center;
          align-items: center;
  margin: 12px;
}
.projectBrickImgContainer {
  grid-area: image;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  position: relative;
}

.brickImagePhone {
  width: 35%;
  max-width: 105px;
  -webkit-align-self: center;
          align-self: center;
  justify-self: center;
}

.brickImageLaptop {
  width: 85%;
  -webkit-align-self: center;
          align-self: center;
  justify-self: center;
}

@media (max-width: 600px) {
  .projectBrick {
    grid-template-columns: auto;
    grid-template-areas: "image" "description" "logos" "button";
    min-width: 325px;
  }
}

.brickDetails {
  grid-area: description;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 12px;
}

.brickTitle {
  margin: 0px;
  font-size: 24pt;
  font-weight: 500;
}

.brickDate {
  margin: 0px;
  font-size: 8pt;
  font-weight: 400;
}

.brickLogoContainer {
  grid-area: logos;
}

.brickJob {
  margin: 0px;
  font-weight: 500;
  font-size: 11pt;
  margin-bottom: 10px;
}

.brickDescription {
  margin: 0px;
  font-size: 10pt;
  font-weight: 300;
}

.brickButton {
  grid-area: button;
  font-size: 10pt;
  color: white;

  padding: 4px 6px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
}

.brickButtonLink {
  justify-self: center;
}

.brickLogoContainer {
  display: -webkit-flex;
  display: flex;
  justify-self: center;
  width: 80%;
  border-radius: 500px;
  padding: 4px 2px;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.brickLogo {
  width: 25px;
  height: 25px;
}

#projects {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-area: projects;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin-top: 12px;
}

.projectContainer {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  border-radius: 4px;
  padding: 12px;
  height: -webkit-max-content;
  height: max-content;
}

@media (max-width: 400px) {
  .projectContainer {
    margin: 6px;
    padding: 0px;
  }
}

.projectContainerTitle {
  color: var(--title-color);
  font-size: 20pt;
}

@media (max-width: 400px) {
  .projectContainerTitle {
    font-size: 16pt;
  }
}

#bubbleContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  justify-self: center;
  width: 90vw;
  height: 90vw;
  max-width: 600px;
  max-height: 500px;
  position: relative;
  background-color: rgb(23, 23, 23, 0.7);
  overflow: hidden;
}

.bubbleCover {
  width: 100%;
  height: 100%;
  background-color: var(--COLOR-3);
  position: absolute;
  top: 0px;
  transition: top 0.5s ease;
}

.skillLevel {
  position: absolute;
  bottom: 30px;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-color: white;
  padding: 2px 4px;
  border-radius: 4px;
}

.skillLevelText {
  margin: 0px;
  font-size: 9pt;
  font-weight: 500;
}

.bubbleRow {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#bubbleBtn {
  position: absolute;
  top: 10px;
}

.bubble {
  margin-left: 10px;
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 12px;
  background-color: var(--COLOR-3);
  background: rgb(236, 255, 0);
  background: linear-gradient(
    180deg,
    rgba(236, 255, 0, 1) 0%,
    rgba(255, 177, 0, 1) 50%,
    var(--COLOR-4)
  );
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: all ease 0.8s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.bubbleImageWrapper {
  width: 99%;
  height: 99%;
  border-radius: 50%;
  background-color: var(--COLOR-3);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}

.bubbleImage {
  width: 65%;
  max-height: 70%;
  min-width: 15px;
  min-height: 15px;
  position: absolute;
}

.bubbleText {
  margin: 0px;
}

@media (max-width: 400px) {
  #bubbleContainer {
    width: 95vw;
    height: 105vw;
  }
  .bubble {
    max-width: 80px;
    max-height: 80px;
    margin-left: 5px;
  }
}

#bubbleDetails {
  width: 90%;
  height: 90%;
  background-color: var(--COLOR-3);
  border-radius: 15px;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

#bubbleDetailImageWrapper {
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
}
#bubbleDetailImage {
  width: 60%;

  max-height: 60%;
  -webkit-align-self: center;
          align-self: center;
}

#bubbleDetailsInfo {
  margin: 10px;
  padding: 4px;
  top: 10px;
}

#bubbleDetailTitle {
  font-size: 24pt;
  margin: 0px;
  font-weight: 600;
}

#bubbleDetailText {
  font-weight: 300;
  margin-top: 4px;
  font-size: 11pt;
}

#bubbleDetailsSkillWrapper {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-top: 4px;
}

#bubbleDetailsSkill {
  position: absolute;
  background-color: grey;
  height: 2px;
  left: 0px;
  transition: left 1s ease;
}

#bubbleDetailsMeter {
  width: 100%;
  height: 2px;
  margin-bottom: 4px;
  background: rgb(236, 255, 0);
  background: linear-gradient(
    270deg,
    rgba(236, 255, 0, 1) 0%,
    rgba(255, 177, 0, 1) 50%,
    var(--COLOR-4)
  );
  position: relative;
  overflow: hidden;
}

#bubbleDetailSkillPercentage {
  margin: 0px;
  font-size: 9pt;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

#skillContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-area: projects;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin-top: 12px;
}

.skillSubContainer {
  margin: 12px;
  padding: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 4px;
  height: -webkit-max-content;
  height: max-content;
  min-width: 300px;
}

.wrapperDiv {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.skillMainTitle {
  color: var(--title-color);
  font-size: 20pt;
  margin-bottom: 12px;

  justify-self: center;
}

@media (max-width: 400px) {
  .skillMainTitle {
    font-size: 16pt;
  }
}

#searchHelperText {
  font-size: 9pt;
  font-weight: 400;
  margin: 0px;
  margin-bottom: 2px;
  color: var(--title-color);
}
#searchWrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
}

#searchContainer {
  display: -webkit-flex;
  display: flex;
  width: -webkit-max-content;
  width: max-content;
  min-width: 250px;
  background-color: rgb(23, 23, 23, 0.7);
  padding: 8px;
  border-radius: 4px;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

#skillSearchField {
  width: 95%;
  border: none;
  border-radius: 0px;
  outline: none;
  padding: 6px;
  background-color: transparent;
  color: var(--COLOR-3);
  border-bottom: 2px solid black;
  font-size: 10pt;
  transition: all 0.4s;
}

#skillSearchField:focus {
  border-bottom: 2px solid var(--COLOR-4);
}

.skillLogo {
  width: 40px;
  height: auto;
  -webkit-align-self: start;
          align-self: start;
}

#codeSkills {
  -webkit-flex: 2 1;
          flex: 2 1;
}
#designSkills {
  -webkit-flex: 2 1;
          flex: 2 1;
}

#otherSkills {
  -webkit-flex: 1 1;
          flex: 1 1;
}

#appleWatchContainer {
  position: relative;
  display: -webkit-flex;
  display: flex;
  justify-self: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  height: 550px;
  overflow: hidden;
  margin-top: 12px;
  width: 100%;
}

@media (max-width: 400px) {
  #appleWatchContainer {
    height: 105vw;
  }
}

#header {
  min-height: -webkit-max-content;
  min-height: max-content;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  grid-template-areas: "image titleText";
}

#titleSection {
  font-family: "Open Sans", sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  justify-self: center;
  height: 150px;
  width: 475px;
  max-width: 100vw;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

#firstName {
  color: var(--COLOR-1);
  margin: 0px;
  font-weight: 800;
  font-size: 120pt;
  position: fixed;
  top: -2vw;
  z-index: -1;
  opacity: 0.9;
}

#lastName {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  justify-self: flex-end;
  font-weight: 200;
  font-size: 32pt;
  color: var(--COLOR-3);
  margin: 0px;
}

@media (max-width: 400px) {
  #firstName {
    font-size: 95pt;
  }

  #titleSection {
    height: 140px;
    width: 450px;
    max-width: 95vw;
  }
}

#headerImage {
  grid-area: image;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 20vw;
  height: 20vw;
  max-width: 125px;
  max-height: 125px;
  min-width: 80px;
  min-height: 80px;
  border-radius: 50%;
  background-color: var(--accent-color-dark);
  overflow: hidden;
}

#titleSection {
  grid-area: titleText;
}

#innerCircle {
  width: 95%;
  height: 95%;
  border-radius: 50%;
  overflow: hidden;
}

#profileImage {
  width: 115%;
  margin-top: -20px;
  margin-left: -5px;
}

#navbar {
  grid-area: nav;
  display: grid;
  grid-template-areas: "navigation" "underline";
  width: 100vw;
  color: rgb(23, 23, 23);
  background-color: rgb(23, 23, 23, 0.7);
}

#navigationOptions {
  width: -webkit-max-content;
  width: max-content;
  min-width: 375px;
  grid-area: navigation;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

@media (max-width: 400px) {
  #navigationOptions {
    width: 100vw;
  }
}

.navOption {
  color: var(--COLOR-3);
  margin: 6px;
  cursor: pointer;
  font-weight: 100;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 100;
}

#underline {
  grid-area: underline;
  width: 80%;
  border-bottom: 2px solid var(--COLOR-4);
  margin: auto;
  transition: margin 300ms, width 300ms;
}

.NavbarCell {
  padding: 0px 4px;
}

.NavbarCell {
  display: flex column;
  cursor: pointer;
}

