#aboutContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 85vw;
  margin-top: 12px;
  background-color: var(--COLOR-3);
  padding: 12px;
  border-radius: 4px;
  max-width: 600px;
}

#profileWrapper {
  width: 20vw;
  height: 20vw;
  min-width: 150px;
  min-height: 150px;
  max-width: 200px;
  max-height: 200px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  position: relative;
  align-content: center;
}

#profileImage {
  position: absolute;
  top: 10px;
  left: -8px;
}

#aboutText {
  font-size: 11pt;
  font-weight: 300;
  margin: 0px 20px;
}

.aboutTitle {
  margin: 4px;
  margin-top: 16px;
  font-size: 12pt;
  font-weight: 500;
  color: var(--COLOR-background);
  align-self: flex-start;
  margin-left: 20px;
}

#interestsRow {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.interest {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.interestIcon {
  height: 25px;
}

.interestText {
  margin: 0px;
  margin-top: 4px;
  font-size: 8pt;
}

#aboutSocialMediaContainer {
  display: flex;
  width: 100%;
  margin-top: 12px;
  justify-content: space-evenly;
}

.contactCell {
  display: flex;
  width: 100px;
  justify-content: space-evenly;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
}

.contactLink {
  text-decoration: none;
  color: inherit;
}
.contactText {
  text-decoration: none;
  margin: 0px;
  font-size: 10pt;
  font-weight: 600;
}
